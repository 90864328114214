import * as React from 'react';
import { Products } from './Products/Products';
import { PopularProducts } from './PopularProducts/PopularProducts';
import { MainSlider } from '../../../components/Slider/MainSlider/MainSlider';
import { OfferOfMonthSlider } from '../../../components/Slider/OfferOfMonthSlider/OfferOfMonthSlider';
import { Link } from 'react-router-dom';
import './LandingPage.scss';

interface Props {
  offersOfMonthSlider: any;
  promotedProductsSlider: any;
  mainSlider: any;
  products: any[];
}

export function LandingPage(props: Props) {
  const { offersOfMonthSlider, mainSlider, promotedProductsSlider, products } = props;
  const { images: mainSliderImages, delay: mainSliderDelay } = mainSlider;
  const { images: offersOfMonthSliderImages, delay: offersOfMonthSliderDelay } = offersOfMonthSlider;

  return (
    <>
      {mainSliderImages.length > 0 && <MainSlider images={mainSliderImages} delay={mainSliderDelay} />}
      {/* <Products images={products} /> */}
      <PopularProducts promotedProductsSlider={promotedProductsSlider} />
      {offersOfMonthSliderImages.length > 0 && (
        <OfferOfMonthSlider images={offersOfMonthSliderImages} delay={offersOfMonthSliderDelay} />
      )}
      <div className="bSliderWrapper">
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <div className="bSlider">
                <div className="eLostTicketContainer">
                  <Link to={{ pathname: '/ParentInvite', state: { fromPictureClick: true } }}>
                    <img className="eSliderImageSingle" src="/dist/images/icon/lostTickets.png" alt="Lost Tickets" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
