import * as React from 'react';
import * as Yup from 'yup';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { Button } from 'Src/components/Button/Button';
import { Component } from 'react';
import { School } from 'Src/models/schools';
import { AllSchool } from 'Src/models/allSchools';
import { AppCustomer } from '../../../AppOrders';
import { getSchoolByParentCode } from '../../../../../services/customer/schools';
import { checkValidParentCode, searchSchools, verifySchools } from '../../../../../services/public/register';
import { getFromHistory } from '../../../../../helpers/history';
import { History } from 'history';

interface Props {
  onCancel: () => void;
  onSubmit: (data: AddStudentPayload) => void;
  onFormFieldsStateChange: (shouldShow: boolean) => void;
  customer: AppCustomer;
  history: History;
}

interface State {
  verifiedSchool?: School;
  selectedSchool?: AllSchool;
  parentCode: string;
  isError: boolean;
  schoolName: string;
  city: string;
  searchResults: AllSchool[];
  isManualSchoolEntry: boolean;
  hasSearched: boolean;
  searchErrors: {
    schoolName: string;
  };
  tooManyResults: boolean;
  isVerifying: boolean;
  verificationError: string;
  showSearchForm: boolean;
  schoolNotServed: boolean;
}

interface AddStudentPayload {
  formId?: string;
  manualFormName?: string;
  schoolId?: string;
  selectedSchoolId?: string;
  forename: string;
  surname: string;
  manualSchoolName?: string;
  schoolNotServed?: boolean;
  hasForms?: boolean;
}

export class CustomerAddStudentForm extends Component<Props, State> {
  prevShouldShowFormFields: boolean | undefined = undefined;

  constructor(props) {
    super(props);

    const { history } = props;
    const schoolCode = getFromHistory(history, 'schoolCode');
    const isSchoolCodeExist = typeof schoolCode !== 'undefined' && schoolCode !== '';

    this.state = {
      verifiedSchool: undefined,
      selectedSchool: undefined,
      parentCode: isSchoolCodeExist ? schoolCode : '',
      isError: false,
      schoolName: '',
      city: '',
      searchResults: [],
      isManualSchoolEntry: false,
      hasSearched: false,
      searchErrors: {
        schoolName: '',
      },
      tooManyResults: false,
      isVerifying: false,
      verificationError: '',
      showSearchForm: !isSchoolCodeExist,
      schoolNotServed: false,
    };
  }

  componentDidMount() {
    const { history } = this.props;
    const schoolCode = getFromHistory(history, 'schoolCode');
    const isSchoolCodeExist = typeof schoolCode !== 'undefined' && schoolCode !== '';

    if (isSchoolCodeExist) {
      // If a schoolCode exists, validate it
      this.setState({ parentCode: schoolCode }, () => {
        this.onNextClick(true); // Pass a flag to distinguish initialization calls
      });
    } else {
      // If no schoolCode, show the search form
      this.setState({ showSearchForm: true });
    }
  }

  onNextClick = (isInitialization = false) => {
    const { parentCode } = this.state;
    const { customer } = this.props;

    checkValidParentCode({ parentCode })
      .then(res => {
        const { isValid } = res;

        if (isValid) {
          return getSchoolByParentCode(customer, parentCode);
        } else if (isInitialization) {
          // If this is during initialization and schoolCode is invalid, show the search form
          this.setState({
            showSearchForm: true,
            isError: false,
            hasSearched: false,
          });
          return undefined;
        }
      })
      .then(school => {
        if (school) {
          const verifiedSchool = {
            ...school,
            forms: Array.isArray(school.forms) ? school.forms : [],
          };

          this.setState({
            verifiedSchool: verifiedSchool,
            isError: false,
            hasSearched: true,
            showSearchForm: false,
            isManualSchoolEntry: false,
            schoolNotServed: false,
          });
        } else if (!isInitialization) {
          // Show error if the manual call to `onNextClick` fails
          this.setState({
            isError: true,
            hasSearched: false,
          });
        }
      })
      .catch(() => {
        this.setState({
          isError: true,
          hasSearched: false,
        });
      });
  };

  handleShowSearchForm = () => {
    this.setState({
      showSearchForm: true,
      schoolName: '',
      selectedSchool: undefined,
      verifiedSchool: undefined,
      parentCode: '',
      isManualSchoolEntry: false,
      isError: false,
      searchResults: [],
      tooManyResults: false,
      verificationError: '',
      hasSearched: false,
    });
  };

  onSearchSchools = (event?: React.FormEvent<HTMLFormElement>) => {
    if (event) {
      event.preventDefault();
    }

    const { schoolName, city } = this.state;

    this.setState({
      hasSearched: true,
      searchResults: [],
      searchErrors: { schoolName: '' },
      tooManyResults: false,
    });

    if (schoolName.trim().length < 3) {
      this.setState({
        searchErrors: {
          schoolName: 'School name must be at least 3 characters long.',
        },
      });
      return;
    }

    searchSchools(schoolName, city)
      .then(results => {
        if (results.length > 10) {
          this.setState({
            tooManyResults: true,
            searchResults: [],
            isError: false,
          });
        } else {
          this.setState({
            searchResults: results,
            isError: false,
            tooManyResults: false,
          });
        }
      })
      .catch(() => {
        this.setState({ isError: true, searchResults: [], tooManyResults: false });
      });
  };

  onSelectSchool = async (selectedSchool: AllSchool) => {
    const { dfesNumber, leaNumber } = selectedSchool;

    this.setState({
      verificationError: '',
      selectedSchool: selectedSchool,
      schoolNotServed: false,
    });

    if (!dfesNumber || !leaNumber) {
      this.setState({
        verifiedSchool: undefined,
        schoolNotServed: true,
      });
      return;
    }

    this.setState({ isVerifying: true });

    try {
      const verificationResult = await verifySchools(dfesNumber, leaNumber);

      if (verificationResult?.id) {
        const verifiedSchool = {
          ...verificationResult,
          forms: Array.isArray(verificationResult.forms) ? verificationResult.forms : [],
        };

        this.setState({
          verifiedSchool: verifiedSchool,
          parentCode: verificationResult.parentCode,
          isVerifying: false,
          schoolNotServed: false,
          showSearchForm: false,
        });
      } else {
        this.setState({
          verifiedSchool: undefined,
          isVerifying: false,
          schoolNotServed: true,
        });
      }
    } catch (error) {
      this.setState({
        verificationError: 'An error occurred while verifying the school. Please try again.',
        isVerifying: false,
        schoolNotServed: false,
      });
    }
  };

  render() {
    const { onSubmit, onCancel, history } = this.props;
    const {
      verifiedSchool,
      selectedSchool,
      isError,
      schoolName,
      city,
      searchResults,
      isManualSchoolEntry,
      hasSearched,
      searchErrors,
      tooManyResults,
      isVerifying,
      verificationError,
      showSearchForm,
      schoolNotServed,
    } = this.state;

    const hasForms = Array.isArray(verifiedSchool?.forms) && verifiedSchool.forms.length > 0;

    const shouldEnterManualSchoolName = isManualSchoolEntry;

    const shouldEnterManualFormNameForManualEntry = isManualSchoolEntry || schoolNotServed;
    const shouldEnterManualFormNameForNoForms = (!hasForms && verifiedSchool?.id) || isManualSchoolEntry;
    const shouldEnterManualFormName = shouldEnterManualFormNameForManualEntry || shouldEnterManualFormNameForNoForms;

    const shouldShowFormFields = isManualSchoolEntry || !!verifiedSchool || !!selectedSchool;

    if (this.prevShouldShowFormFields !== shouldShowFormFields) {
      this.props.onFormFieldsStateChange(shouldShowFormFields);
      this.prevShouldShowFormFields = shouldShowFormFields; // Update the tracked value
    }

    const CustomerStudentSchema = Yup.object().shape({
      formId: Yup.string(),
      manualFormName: Yup.string(),
      manualSchoolName: Yup.string(),
      forename: Yup.string().required('Required'),
      surname: Yup.string().required('Required'),
    });

    const customerStudentForm = {
      formId: '',
      manualFormName: '',
      forename: '',
      surname: '',
      manualSchoolName: '',
    };

    return (
      <div className="mt-3">
        {showSearchForm && (
          <div className="card mb-4">
            <div className="card-header bg-primary text-white">
              <h5 className="mb-0">Search for Your School</h5>
            </div>
            <div className="card-body">
              <form onSubmit={this.onSearchSchools} noValidate>
                <div className="form-group">
                  <label htmlFor="schoolName">
                    School Name <span className="text-danger">*</span>{' '}
                  </label>
                  <input
                    id="schoolName"
                    className={`form-control ${searchErrors.schoolName ? 'is-invalid' : ''}`}
                    placeholder="Enter at least 3 characters of the school name"
                    value={schoolName}
                    onChange={e => this.setState({ schoolName: e.target.value })}
                  />
                  {searchErrors.schoolName && <div className="invalid-feedback">{searchErrors.schoolName}</div>}
                </div>

                <div className="form-group">
                  <label htmlFor="city">Town</label>
                  <input
                    id="city"
                    className="form-control"
                    placeholder="Enter part of the town name (optional)"
                    value={city}
                    onChange={e => this.setState({ city: e.target.value })}
                  />
                </div>

                <Button
                  type="submit"
                  onClick={undefined}
                  text="Search"
                  customClass="mt-3 mb-3 btn-primary"
                  disabled={isVerifying}
                />

                {hasSearched && tooManyResults && !isError && (
                  <div className="mt-4 alert alert-warning" role="alert">
                    Too many results found. Please refine your search.
                  </div>
                )}

                {hasSearched && searchResults.length > 0 && !tooManyResults && (
                  <div className="mt-4">
                    <h5 className="mb-3">Search Results</h5>
                    <ul className="list-group">
                      {searchResults.map(school => (
                        <li
                          key={school.id}
                          className="list-group-item list-group-item-action"
                          onClick={() => this.onSelectSchool(school)}
                          style={{ cursor: 'pointer' }}
                          tabIndex={0}
                          onKeyPress={e => {
                            if (e.key === 'Enter' || e.key === ' ') {
                              this.onSelectSchool(school);
                            }
                          }}
                        >
                          <strong>{school.name}</strong> <span>({school.city || 'N/A'})</span>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}

                {hasSearched &&
                  searchResults.length === 0 &&
                  !isError &&
                  !tooManyResults &&
                  !selectedSchool &&
                  !searchErrors.schoolName && (
                    <div className="mt-4 alert alert-info" role="alert">
                      No results found. You can enter your school name manually using the My school is not found link
                      below.
                    </div>
                  )}

                {isError && (
                  <div className="mt-4 alert alert-danger" role="alert">
                    An error occurred while searching. Please try again later.
                  </div>
                )}

                {verificationError && (
                  <div className="mt-4 alert alert-danger" role="alert">
                    {verificationError}
                  </div>
                )}

                {hasSearched && !isManualSchoolEntry && !tooManyResults && !searchErrors.schoolName && (
                  <p className="mt-3">
                    <a
                      href="#"
                      className="text-decoration-underline"
                      onClick={e => {
                        e.preventDefault();
                        this.setState({
                          isManualSchoolEntry: true,
                          verifiedSchool: undefined,
                          selectedSchool: undefined,
                          parentCode: '',
                          isError: false,
                          searchErrors: { schoolName: '' },
                          tooManyResults: false,
                          verificationError: '',
                        });
                      }}
                    >
                      My school is not found
                    </a>
                  </p>
                )}
              </form>
            </div>
          </div>
        )}

        {verifiedSchool && !isManualSchoolEntry && (
          <div className="form-group">
            <label htmlFor="schoolNameDisplay">
              School Name{' '}
              <button
                type="button"
                className="btn btn-link p-0 ml-2"
                onClick={this.handleShowSearchForm}
                style={{ textDecoration: 'underline', color: '#007bff' }}
                aria-label="Incorrect school? Search your school"
              >
                Incorrect school? Search your school
              </button>
            </label>
            <input id="schoolNameDisplay" type="text" className="form-control" value={verifiedSchool.name} readOnly />
          </div>
        )}

        {shouldShowFormFields && (
          <Formik
            initialValues={customerStudentForm}
            validationSchema={CustomerStudentSchema}
            onSubmit={values => {
              const { selectedSchool, verifiedSchool, isManualSchoolEntry, schoolNotServed, parentCode } = this.state;

              const hasForms = Array.isArray(verifiedSchool?.forms) && verifiedSchool.forms.length > 0;

              //              const shouldEnterManualFormNameForManualEntry = isManualSchoolEntry || schoolNotServed;
              //              const shouldEnterManualFormNameForNoForms = (!hasForms && verifiedSchool?.id) || isManualSchoolEntry;

              const schoolId = verifiedSchool?.id || null;
              const selectedSchoolId = selectedSchool?.id || null;

              const isSchoolNotServed = !verifiedSchool || schoolNotServed;

              const payload: AddStudentPayload = {
                ...values,
                schoolId: schoolId,
                selectedSchoolId: selectedSchoolId,
                schoolNotServed: isSchoolNotServed,
                hasForms: hasForms,
              };

              if (shouldEnterManualFormNameForManualEntry) {
                payload.manualSchoolName = values.manualSchoolName.trim();
                payload.formId = undefined;
              }

              if (shouldEnterManualFormNameForNoForms) {
                payload.manualFormName = values.manualFormName.trim();
              }

              onSubmit(payload);

              const searchParams = new URLSearchParams();

              if (!isManualSchoolEntry && parentCode) {
                searchParams.set('schoolCode', parentCode);
              }

              history.push({
                search: searchParams.toString(),
              });
            }}
          >
            {({ handleSubmit }) => (
              <Form>
                {shouldEnterManualSchoolName && (
                  <div className="form-group">
                    <label htmlFor="manualSchoolName">Enter School Name</label>
                    <Field
                      name="manualSchoolName"
                      className="form-control"
                      placeholder="Enter school name"
                      id="manualSchoolName"
                    />
                    <ErrorMessage component="div" className="alert alert-danger" name="manualSchoolName" />
                  </div>
                )}

                {shouldEnterManualFormName && (
                  <div className="form-group">
                    <label htmlFor="manualFormName">Enter Form Name</label>
                    <Field
                      name="manualFormName"
                      className="form-control"
                      placeholder="Enter form name"
                      id="manualFormName"
                    />
                    <ErrorMessage component="div" className="alert alert-danger" name="manualFormName" />
                  </div>
                )}

                {verifiedSchool && !isManualSchoolEntry && hasForms && (
                  <div className="form-group">
                    <label htmlFor="formId">Form</label>
                    <Field name="formId" as="select" className="form-control mb-3" id="formId">
                      <option value="">-- Select Student Form --</option>
                      {verifiedSchool.forms.map(form => (
                        <option key={form.id} value={form.id}>
                          {form.name}
                        </option>
                      ))}
                    </Field>
                    <ErrorMessage component="div" className="alert alert-danger" name="formId" />
                  </div>
                )}

                <div className="form-group">
                  <label htmlFor="forename">Student Forename</label>
                  <Field name="forename" className="form-control mb-3" id="forename" />
                  <ErrorMessage component="div" className="alert alert-danger" name="forename" />
                </div>

                <div className="form-group">
                  <label htmlFor="surname">Student Surname</label>
                  <Field name="surname" className="form-control mb-3" id="surname" />
                  <ErrorMessage component="div" className="alert alert-danger" name="surname" />
                </div>

                {/* Buttons */}
                <Button onClick={onCancel} text={'Cancel'} customClass={'mt-3 mb-3 mr-3 btn-secondary'} />
                <Button
                  onClick={handleSubmit}
                  text={'Continue'}
                  customClass={'mt-3 mb-3 btn btn-primary'}
                  type={'submit'}
                />
              </Form>
            )}
          </Formik>
        )}
      </div>
    );
  }
}
